/* Modal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7); /* Полупрозрачный фон */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Устанавливаем на передний план */
  }
  
  .modal-content {
    background: white;
    padding: 10px;
    border-radius: 8px;
    text-align: center;
    position: relative;
    max-width: 90%;  /* Ограничиваем максимальную ширину */
    max-height: 90%; /* Ограничиваем максимальную высоту */
    overflow: auto;   /* Добавляем прокрутку */
  }
  
  .modal-image {
    max-width: 100%;
    max-height: 70vh; /* Ограничиваем максимальную высоту изображения */
    object-fit: contain; /* Сохраняем пропорции жизненного цикла */
  }
  
  .modal-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-around; /* Выравниваем кнопки */
  }
  
:root {
    --bg-color: #ffffff;
    --text-color: #000000;
    --hint-color: #6c757d;
    --secondary-bg-color: #f0f0f0;
    --button-color: #0088cc;
    --button-text-color: #ffffff;
  }
  
  .greetings-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px;
    background-color: var(--bg-color);
  }
  
  .greeting-item {
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid var(--hint-color);
    border-radius: 10px;
    background-color: var(--secondary-bg-color);
    color: var(--text-color);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .greeting-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .greeting-text {
    font-size: 16px;
    color: var(--text-color);
  }
  